/* ============================================================================
	Imports
============================================================================ */
/* ============================================================================
	Vars
============================================================================ */
/* ============================================================================
	Mobile
============================================================================ */
/* ============================================================================
	Tablet
============================================================================ */
/* ============================================================================
	Desktop
============================================================================ */
/* ============================================================================
	Print
============================================================================ */
/* ============================================================================
	Fonts
============================================================================ */
@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto/roboto-regular.woff2") format("woff2"), url("../fonts/roboto/roboto-regular.woff") format("woff"), url("../fonts/roboto/roboto-regular.ttf") format("truetype");
}
@font-face {
  font-family: roboto;
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/roboto/roboto-italic.woff2") format("woff2"), url("../fonts/roboto/roboto-italic.woff") format("woff"), url("../fonts/roboto/roboto-italic.ttf") format("truetype");
}
@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto/roboto-medium.woff2") format("woff2"), url("../fonts/roboto/roboto-medium.woff") format("woff"), url("../fonts/roboto/roboto-medium.ttf") format("truetype");
}
@font-face {
  font-family: roboto;
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/roboto/roboto-mediumitalic.woff2") format("woff2"), url("../fonts/roboto/roboto-mediumitalic.woff") format("woff"), url("../fonts/roboto/roboto-mediumitalic.ttf") format("truetype");
}
@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/roboto/roboto-medium.woff2") format("woff2"), url("../fonts/roboto/roboto-medium.woff") format("woff"), url("../fonts/roboto/roboto-medium.ttf") format("truetype");
}
@font-face {
  font-family: roboto;
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/roboto/roboto-mediumitalic.woff2") format("woff2"), url("../fonts/roboto/roboto-mediumitalic.woff") format("woff"), url("../fonts/roboto/roboto-mediumitalic.ttf") format("truetype");
}
@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto/roboto-bold.woff2") format("woff2"), url("../fonts/roboto/roboto-bold.woff") format("woff"), url("../fonts/roboto/roboto-bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto;
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/roboto/roboto-bolditalic.woff2") format("woff2"), url("../fonts/roboto/roboto-bolditalic.woff") format("woff"), url("../fonts/roboto/roboto-bolditalic.ttf") format("truetype");
}
/* ============================================================================
	Geral
============================================================================ */
#root, body, html {
  height: 100%;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.show-break-lines {
  white-space: pre-wrap;
}

/* ============================================================================
	AntDesign
============================================================================ */
#root > .ant-layout {
  min-height: 100%;
}

.ant-form .ant-form-item .ant-input-prefix .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-number {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .ant-card-body {
    padding: 10px;
  }
}

.ant-form-item-children {
  display: block;
}

/* ============================================================================
	Content
============================================================================ */
.site-content .site-content-inner .page-content .page-title {
  font-size: 28px;
}
@media screen and (max-width: 767px) {
  .site-content .site-content-inner .page-content .page-title {
    font-size: 20px;
  }
}

.page-listing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.page-listing-header .search {
  flex: 1;
}
@media screen and (min-width: 768px) {
  .page-listing-header .search {
    max-width: 300px;
  }
}
.page-listing-header .actions {
  margin-left: 20px;
}
.page-listing-header .actions .actions-dropdown {
  padding: 0;
  width: 42px;
  text-align: center;
}
.page-listing-header .actions .actions-dropdown i {
  font-size: 24px;
}

.page-listing-body .ant-table {
  background-color: #fff;
}
.page-listing-body .ant-table .ant-table-content .ant-table-body {
  margin: 0;
}
.page-listing-body .ant-table .ant-table-content .ant-table-thead tr th.disable-sort div {
  pointer-events: none;
}
.page-listing-body .ant-table .ant-table-content .ant-table-thead tr th.active {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.page-listing-body .ant-table .ant-table-content .ant-table-thead tr th.actions {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.page-listing-body .ant-table .ant-table-content .ant-table-row td {
  padding-top: 0;
  padding-bottom: 0;
  height: 70px;
}
.page-listing-body .ant-table .ant-table-content .ant-table-row td.active {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.page-listing-body .ant-table .ant-table-content .ant-table-row td.actions {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.page-listing-body .ant-table .ant-table-content .ant-table-row td.actions .actions-dropdown {
  padding: 0;
  width: 42px;
  text-align: center;
}
.page-listing-body .ant-table .ant-table-content .ant-table-row td.actions .actions-dropdown i {
  font-size: 20px;
}
.page-listing-body .ant-table .ant-table-content .ant-table-row td.image {
  padding: 0;
  text-align: center;
}

/*** Listing - Actions ============================== */
.actions-dropdown-menu li a i {
  margin-right: 8px;
}

.page-listing-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
@media screen and (max-width: 1199px) {
  .page-listing-header {
    flex-wrap: wrap;
  }
}
.page-listing-header .ant-select .ant-select-selector {
  padding-left: 20px;
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .page-listing-header .search {
    margin-bottom: 10px;
    width: 100%;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .page-listing-header .search {
    padding-right: 5px;
    width: 50%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .page-listing-header .search .ant-input-search .ant-input-wrapper {
    width: 230px;
  }
}
@media screen and (min-width: 1200px) {
  .page-listing-header .search .ant-input-search .ant-input-wrapper {
    width: 300px;
  }
}
.page-listing-header .search .ant-input-search .ant-input-wrapper .ant-input-affix-wrapper {
  padding-left: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.page-listing-header .search .ant-input-search .ant-input-wrapper .ant-input-group-addon {
  background: none;
}
.page-listing-header .search .ant-input-search .ant-input-wrapper .ant-input-group-addon .ant-btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media screen and (max-width: 767px) {
  .page-listing-header .order {
    margin-bottom: 10px;
    width: 100%;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .page-listing-header .order {
    padding-left: 5px;
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .page-listing-header .order .ant-select {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .page-listing-header .order .ant-select {
    margin-left: 10px;
    width: 200px;
  }
}
.page-listing-header .action-btn {
  vertical-align: top;
  border-radius: 20px;
}
@media screen and (max-width: 1199px) {
  .page-listing-header .action-btn {
    padding: 0;
    width: 40px;
  }
  .page-listing-header .action-btn span {
    display: none;
  }
}
.page-listing-header .action-btn + .action-btn {
  margin-left: 10px;
}
.page-listing-header .action-btn.ant-btn-icon-only {
  padding: 0;
  width: 50px;
  text-align: center;
}
.page-listing-header .btn-group-list-type + .action-btn {
  margin-left: 10px;
}
.page-listing-header .btn-group-list-type .action-btn {
  margin: 0;
  font-size: 17px;
}
.page-listing-header .btn-group-list-type .action-btn:first-child {
  border-radius: 20px 0 0 20px !important;
}
.page-listing-header .btn-group-list-type .action-btn:last-child {
  border-radius: 0 20px 20px 0 !important;
}
.page-listing-header .btn-group-list-type .action-btn.active {
  background-color: #00ac71;
  border-top-color: #00ac71;
  border-bottom-color: #00ac71;
}
.page-listing-header .btn-group-list-type .action-btn.active:first-child {
  border-left-color: #00ac71;
}
.page-listing-header .btn-group-list-type .action-btn.active:last-child {
  border-right-color: #00ac71;
}
.page-listing-header .btns {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 768px) {
  .page-listing-header .btns {
    padding-left: 10px;
  }
}
.page-listing-header .btns .ant-btn .ant-badge {
  color: #fff;
}
.page-listing-header .btns .ant-btn .ant-badge .ant-badge-count {
  box-shadow: none;
}

/*** Modal image ==================================== */
.modal-image .ant-modal-content .ant-modal-body {
  text-align: center;
}
.modal-image .ant-modal-content .ant-modal-body video {
  max-width: 100%;
}
.modal-image .ant-modal-content .ant-modal-body img {
  max-width: 100%;
}

.list-items .list-items-header {
  margin-bottom: 10px;
}
.list-items .list-items-header .row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.list-items .list-items-header .row .col {
  flex: 1 0 0;
  position: relative;
  padding: 5px 10px;
  min-width: 0;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0 1px 0 #fff;
}
.list-items .list-items-body {
  padding-bottom: 20px;
}
.list-items .list-items-body .list-items-item {
  position: relative;
}
.list-items .list-items-body .list-items-item:first-child .row {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.list-items .list-items-body .list-items-item:last-child .row {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.list-items .list-items-body .list-items-item .row {
  background: #fff;
  transition-property: transform, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.list-items .list-items-body .list-items-item:not(.selected) .row:hover {
  background-color: #f0f0f0;
}
.list-items .list-items-body .list-items-item.selected .row {
  box-shadow: 0 0 0 1px rgba(0, 208, 137, 0.6);
  background: #93ffda;
}
.list-items .list-items-body + .ant-pagination {
  margin-top: 10px;
}
.list-items .ant-pagination {
  text-align: center;
}
.list-items.list-type .list-items-header .row .col > div,
.list-items.list-type .list-items-body .list-items-item .row .col > div {
  flex: 1;
}
.list-items.list-type .list-items-header .row .col:not(.no-ellipsis) > div,
.list-items.list-type .list-items-body .list-items-item .row .col:not(.no-ellipsis) > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-items.list-type .list-items-header .row .col.col-select,
.list-items.list-type .list-items-body .list-items-item .row .col.col-select {
  padding-left: 0;
  padding-right: 0;
  max-width: 40px;
}
@media screen and (min-width: 1200px) {
  .list-items.list-type .list-items-header .row .col.col-select,
  .list-items.list-type .list-items-body .list-items-item .row .col.col-select {
    text-align: right;
  }
}
.list-items.list-type .list-items-header .row .col.id,
.list-items.list-type .list-items-body .list-items-item .row .col.id {
  max-width: 90px;
}
.list-items.list-type .list-items-header .row .col.no-padding-horizontal,
.list-items.list-type .list-items-body .list-items-item .row .col.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0;
}
.list-items.list-type .list-items-header .row .col.datetime,
.list-items.list-type .list-items-body .list-items-item .row .col.datetime {
  max-width: 150px;
}
.list-items.list-type .list-items-header .row .col.active,
.list-items.list-type .list-items-body .list-items-item .row .col.active {
  padding-left: 0;
  padding-right: 0;
  max-width: 70px;
  text-align: center;
}
.list-items.list-type .list-items-header .row .col.actions,
.list-items.list-type .list-items-body .list-items-item .row .col.actions {
  padding-left: 0;
  padding-right: 0;
  max-width: 70px;
  text-align: center;
}
@media screen and (max-width: 1199px) {
  .list-items.list-type .list-items-header {
    display: none;
  }
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
  .list-items.list-type .list-items-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
}
.list-items.list-type .list-items-body .list-items-item {
  margin-bottom: 1px;
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
  .list-items.list-type .list-items-body .list-items-item {
    margin-bottom: 30px;
    padding: 0 15px;
    width: 50%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .list-items.list-type .list-items-body .list-items-item {
    width: 33.33%;
  }
}
.list-items.list-type .list-items-body .list-items-item .row {
  position: relative;
}
@media screen and (max-width: 1199px) {
  .list-items.list-type .list-items-body .list-items-item .row {
    padding: 20px;
    text-align: center;
  }
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
  .list-items.list-type .list-items-body .list-items-item .row {
    min-height: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .list-items.list-type .list-items-body .list-items-item .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 1199px) {
  .list-items.list-type .list-items-body .list-items-item .row .col {
    margin-bottom: 15px;
    max-width: 100% !important;
  }
  .list-items.list-type .list-items-body .list-items-item .row .col:not(.actions):not(.active)::before {
    content: attr(data-title);
    font-weight: 700;
    font-size: 14px;
  }
}
@media screen and (min-width: 1200px) {
  .list-items.list-type .list-items-body .list-items-item .row .col {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    padding: 5px 10px;
    min-width: 0;
    min-height: 55px;
    overflow-wrap: break-word;
  }
}
.list-items.list-type .list-items-body .list-items-item .row .col.col-select .ant-checkbox-wrapper {
  padding: 10px;
}
.list-items.list-type .list-items-body .list-items-item .row .col .ant-tag {
  margin: 0 4px;
  border-radius: 11px;
  white-space: normal;
}
.list-items.list-type .list-items-body .list-items-item .row .col .ant-typography {
  margin-bottom: 0;
}
.list-items.list-type .list-items-body .list-items-item .row .col .ant-typography-ellipsis > span {
  display: block;
}
.list-items.list-type .list-items-body .list-items-item .row .col .ant-typography-ellipsis .ant-typography-expand {
  margin: 0;
}
.list-items.list-type .list-items-body .list-items-item .row .col.actions {
  padding: 0;
}
@media screen and (max-width: 1199px) {
  .list-items.list-type .list-items-body .list-items-item .row .col.actions {
    position: absolute;
    top: 20px;
    right: 20px;
    margin-bottom: 0;
  }
}
.list-items.list-type .list-items-body .list-items-item .row .col.actions .actions-dropdown {
  width: 42px;
}
.list-items.list-type .list-items-body .list-items-item .row .col.actions .actions-dropdown i {
  font-size: 25px;
}
.list-items.card-type {
  padding-top: 10px;
}
.list-items.card-type .list-items-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.list-items.card-type .list-items-body .list-items-item {
  margin-bottom: 30px;
  padding: 0 15px;
  width: 25%;
}
@media screen and (max-width: 767px) {
  .list-items.card-type .list-items-body .list-items-item {
    width: 100%;
  }
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
  .list-items.card-type .list-items-body .list-items-item {
    width: 50%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .list-items.card-type .list-items-body .list-items-item {
    width: 33.33%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .list-items.card-type .list-items-body .list-items-item {
    width: 33.33%;
  }
}
.list-items.card-type .list-items-body .list-items-item .row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 24px;
  min-height: 100%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .list-items.card-type .list-items-body .list-items-item .row {
    padding: 14px;
  }
}
.list-items.card-type .list-items-body .list-items-item .row .col {
  width: 100%;
  max-width: 100% !important;
}
.list-items.card-type .list-items-body .list-items-item .row .col h3 {
  padding: 0 15px;
}
.list-items.card-type .list-items-body .list-items-item .row .col .ant-typography-ellipsis > span {
  display: block;
}
.list-items.card-type .list-items-body .list-items-item .row .col .ant-typography-ellipsis .ant-typography-expand {
  margin: 0;
}
.list-items.card-type .list-items-body .list-items-item .row .col .ant-tag {
  margin: 0 4px;
  border-radius: 11px;
  white-space: normal;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block-width-1 {
  width: 100%;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block-width-2 {
  width: 50%;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block-width-3 {
  width: 33.33%;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block-width-4 {
  width: 25%;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block-width-5 {
  width: 20%;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block {
  padding: 5px;
  margin-bottom: 15px;
  background: #f7f7f7;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block .value {
  display: block;
  line-height: 30px;
  font-weight: 700;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.list-items.card-type .list-items-body .list-items-item .row .col.card-block label {
  display: block;
  font-weight: 500;
  font-size: 14px;
}
.list-items.card-type .list-items-body .list-items-item .row .col.col-select {
  margin-top: -15px;
  margin-bottom: 5px;
  text-align: center;
}
.list-items.card-type .list-items-body .list-items-item .row .col.col-select .ant-checkbox-wrapper {
  padding: 10px;
}
.list-items.card-type .list-items-body .list-items-item .row .col.id {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  width: auto;
  font-weight: 700;
  color: #fff;
  background: #d9d9d9;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-shadow: 0 1px 1px #a6a3a3;
}
.list-items.card-type .list-items-body .list-items-item .row .col.approved {
  padding-top: 10px;
}
.list-items.card-type .list-items-body .list-items-item .row .col.active {
  padding-top: 10px;
}
.list-items.card-type .list-items-body .list-items-item .row .col.actions {
  position: absolute;
  top: 20px;
  right: 10px;
  margin-bottom: 0;
  width: auto;
}
.list-items.card-type .list-items-body .list-items-item .row .col.actions .actions-dropdown {
  width: 42px;
  border: none;
  background: none;
  box-shadow: none;
}
.list-items.card-type .list-items-body .list-items-item .row .col.actions .actions-dropdown::after {
  display: none;
}
.list-items.card-type .list-items-body .list-items-item .row .col.actions .actions-dropdown i {
  font-size: 30px;
}

/*** Drawer form ==================================== */
.drawer-form.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.2), 0 0 24px 2px rgba(0, 0, 0, 0.14), 0 0 30px 5px rgba(0, 0, 0, 0.12);
}
.drawer-form .ant-drawer-content-wrapper {
  max-width: 90%;
}
@media screen and (max-width: 767px) {
  .drawer-form .ant-drawer-content-wrapper {
    max-width: 95%;
  }
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content {
  background: #f0f2f5;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body {
  height: 100%;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
  padding: 0;
  height: 100%;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner {
  height: 100%;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-header, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-header, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-header, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  height: 65px;
  background: #fff;
  border-bottom: 1px solid #dadce0;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-header .btn-close, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-header .btn-close, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-header .btn-close, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-header .btn-close {
  padding: 0;
  margin-right: 8px;
  width: 48px;
  height: 48px;
  font-size: 20px;
  color: #747474;
  background: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: none !important;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-header .btn-close:hover, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-header .btn-close:focus, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-header .btn-close:hover, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-header .btn-close:focus, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-header .btn-close:hover, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-header .btn-close:focus, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-header .btn-close:hover, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-header .btn-close:focus {
  color: #202020;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-header .btn-close:active, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-header .btn-close:active, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-header .btn-close:active, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-header .btn-close:active {
  background: #f3f3f3;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-header .ant-drawer-title, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-header .ant-drawer-title, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-header .ant-drawer-title, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-header .ant-drawer-title {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-header .btn-save, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-header .btn-save, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-header .btn-save, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-header .btn-save {
  margin-left: 8px;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .form-body, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .form-body {
  overflow: auto;
  padding: 24px;
  height: calc(100% - 65px);
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-body, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-body {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 65px);
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-body .drawer-form-body-inner, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-body .drawer-form-body-inner {
  padding: 24px;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-form .drawer-form-body .ant-tabs, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .drawer-form-inner .drawer-form-body .ant-tabs {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap {
  min-height: 115px;
  zoom: 1;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap::before, .drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap::after {
  display: table;
  content: "";
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap::after {
  clear: both;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap .media-images-uploader .ant-upload-list {
  float: none;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap .media-images-uploader .ant-upload-list .ant-upload-list-item {
  padding: 0;
  background: #fff;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap .media-images-uploader .ant-upload-list .ant-upload-list-item .ant-upload-list-item-info > span {
  height: 100%;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap .media-images-uploader .ant-upload-list .ant-upload-list-item .ant-upload-list-item-info > span .ant-upload-list-item-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap .media-images-uploader .ant-upload-list .ant-upload-list-item .ant-upload-list-item-info > span .ant-upload-list-item-thumbnail img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.drawer-form .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .media-images-wrap .media-images-uploader.media-images-view .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon-delete {
  display: none;
}

/* ============================================================================
	Templates
============================================================================ */
/* ============================================================================
	Vars
============================================================================ */
/* ============================================================================
	Mobile
============================================================================ */
/* ============================================================================
	Tablet
============================================================================ */
/* ============================================================================
	Desktop
============================================================================ */
/* ============================================================================
	Print
============================================================================ */
.template-default {
  /* ============================================================================
  	Menu
  ============================================================================ */
  /* ============================================================================
  	Header
  ============================================================================ */
  /* ============================================================================
  	Footer
  ============================================================================ */
  /* ============================================================================
  	Content
  ============================================================================ */
}
.template-default .site-menu {
  z-index: 2;
}
.template-default .site-menu.ant-layout-sider {
  overflow: auto;
  position: fixed;
  left: 0;
  height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  scrollbar-width: thin;
  scrollbar-color: #fff #001529;
}
.template-default .site-menu.ant-layout-sider::-webkit-scrollbar {
  width: 6px;
}
.template-default .site-menu.ant-layout-sider::-webkit-scrollbar-track {
  background-color: #001529;
  height: 8px;
}
.template-default .site-menu.ant-layout-sider::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.template-default .site-menu.ant-layout-sider::-webkit-scrollbar-button {
  display: none;
}
.template-default .site-menu .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  height: 64px;
  background-color: #f4f4fa;
}
.template-default .site-menu .ant-menu-root > .ant-menu-item:first-child,
.template-default .site-menu .ant-menu-root > .ant-menu-submenu:first-child {
  margin-top: 0;
}
.template-default .site-menu .ant-menu-root > .ant-menu-item .anticon,
.template-default .site-menu .ant-menu-root > .ant-menu-submenu .anticon {
  min-width: 20px;
  font-size: 20px;
  vertical-align: -5px;
}
.template-default .site-header {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 21, 41, 0.2);
}
.template-default .site-header .trigger {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.template-default .site-header .trigger:hover {
  color: #1890ff;
}
.template-default .site-header .inner {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  text-align: right;
}
.template-default .site-header .inner img {
  max-width: 50px;
}
.template-default .site-header .inner .new-message {
  margin-right: 20px;
}
.template-default .site-header .inner .new-message a {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
  height: 100%;
}
.template-default .site-header .inner .new-message a i {
  font-size: 30px;
}
.template-default .site-header .inner .new-message a .ant-badge {
  position: absolute;
  top: 10px;
  right: 0;
}
.template-default .site-header .inner .user-dropdown {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.template-default .site-header .inner .user-dropdown .ant-avatar {
  margin-right: 8px;
}
.template-default .site-header .inner .user-dropdown .ant-avatar .anticon {
  font-size: inherit;
}
.template-default .site-header .inner .user-dropdown > .anticon {
  margin-left: 3px;
}
.template-default .site-header .inner .user-dropdown .ant-spin {
  margin-left: 10px;
}
.template-default .site-footer {
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
}
.template-default .site-footer .inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.template-default .site-footer .inner img {
  max-width: 50px;
}
.template-default .site-content {
  display: flex;
}
.template-default .site-content .site-content-inner {
  flex: 1;
  margin-top: 84px;
  max-width: 100%;
}
@media screen and (min-width: 1200px) {
  .template-default .site-content .site-content-inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.template-default .site-content .site-content-inner .page-content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 1199px) {
  .template-default .site-content .site-content-inner .page-content {
    padding: 10px;
  }
}

/* ============================================================================
	Drawer
============================================================================ */
.template-default-ant-drawer {
  z-index: 2;
}
.template-default-ant-drawer .ant-drawer-content-wrapper .ant-drawer-content {
  background-color: #001529;
}
.template-default-ant-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
  padding: 0;
}
.template-default-ant-drawer .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  height: 64px;
  background-color: #f4f4fa;
}
.template-default-ant-drawer .ant-menu-root > .ant-menu-item:first-child {
  margin-top: 0;
}

/* ============================================================================
	Vars
============================================================================ */
/* ============================================================================
	Mobile
============================================================================ */
/* ============================================================================
	Tablet
============================================================================ */
/* ============================================================================
	Desktop
============================================================================ */
/* ============================================================================
	Print
============================================================================ */
.template-login {
  /* ============================================================================
  	Footer
  ============================================================================ */
  /* ============================================================================
  	Content
  ============================================================================ */
}
.template-login .site-footer {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.template-login .site-footer img {
  max-width: 50px;
}
.template-login .site-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
}
.template-login .site-content .site-content-logo {
  margin-bottom: 40px;
}
.template-login .site-content .site-content-logo img {
  height: 55px;
}
.template-login .site-content .site-content-inner {
  width: 100%;
  max-width: 350px;
}
/* ============================================================================
	Pages
============================================================================ */
.page-account .site-content .site-content-inner .page-content .avatar {
  margin-bottom: 30px;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader.not-sending > .ant-upload .ant-progress .ant-progress-inner .ant-progress-text {
  display: none;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload {
  position: relative;
  width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 50%;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload .ant-upload-text {
  margin-top: 8px;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload i {
  font-size: 32px;
  color: #999;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload img {
  border-radius: 50%;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload .ant-progress {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1000ms linear;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload .ant-progress.ant-progress-status-success {
  opacity: 0;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload .ant-progress .ant-progress-inner .ant-progress-circle-trail {
  stroke: transparent;
}
.page-account .site-content .site-content-inner .page-content .avatar .avatar-uploader > .ant-upload .ant-progress .ant-progress-inner .ant-progress-text {
  color: #fff;
}
.page-account .site-content .site-content-inner .page-content .roles {
  margin-bottom: 30px;
}
.page-account .site-content .site-content-inner .page-content .roles .ant-tag {
  margin-bottom: 8px;
}

.page-error-404.page-error-404-public .site-content {
  display: flex;
}
.page-error-404.page-error-404-public .site-content .site-content-inner {
  flex: 1;
  justify-content: center;
}
.page-error-404 .site-content .site-content-inner {
  display: flex;
  align-items: center;
}
.page-error-404 .site-content .site-content-inner .page-content {
  text-align: center;
}
.page-error-404 .site-content .site-content-inner .page-content .icon {
  margin: 0 auto 50px;
  width: 184px;
}

/* ============================================================================
	Vars
============================================================================ */
.page-home .site-content .site-content-inner .page-content .page-title {
  margin-bottom: 30px;
}
.page-home .site-content .site-content-inner .page-content .cards .groupData {
  margin-bottom: 30px;
}
.page-home .site-content .site-content-inner .page-content .cards .groupData .ant-row-flex .ant-col {
  margin-bottom: 16px;
}
.page-home .site-content .site-content-inner .page-content .cards .groupData .ant-card {
  height: 100%;
}
.page-home .site-content .site-content-inner .page-content .cards .groupData .ant-card[data-has-link]:hover {
  cursor: pointer;
  border-color: #00d089;
  box-shadow: 0 0 10px rgba(0, 208, 137, 0.2);
}
.page-home .site-content .site-content-inner .page-content .cards .groupData .ant-card h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.page-home .site-content .site-content-inner .page-content .cards .groupData .ant-card .numberTotal {
  font-weight: 700;
  font-size: 30px;
  color: #333;
}

.page-login .site-content .site-content-inner .page-content .ant-alert {
  margin-bottom: 20px;
}